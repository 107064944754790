<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-toolbar
          color="primary"
          dark
        >
          <span v-if="employeeName">{{ employeeName }}</span>
          <v-progress-circular
            v-else
            indeterminate
            dark
          ></v-progress-circular>
          <v-spacer></v-spacer>
          <v-icon>mdi-account</v-icon>
          <v-icon>mdi-pencil</v-icon>
        </v-toolbar>
        <v-alert
          class="mt-2"
          type="error"
          dismissible
          v-for="(item, index) in empPutErrors.concat(empGetErrors)"
          :key="index"
        >
          {{ item.first() }}
        </v-alert>
      </v-col>
      <v-col
        offset-lg="8"
        offset-md="8"
        lg="4"
        md="4"
        cols="12"
      >
        <v-text-field
          label="Employee ID"
          disabled
          :loading="empGetting"
          :value="employeeId"
        ></v-text-field>
      </v-col>
      <v-col
        lg="4"
        md="4"
        cols="12"
      >
        <v-text-field
          label="First Name"
          v-model="firstname"
          :loading="empGetting"
        ></v-text-field>
      </v-col>
      <v-col
        lg="4"
        md="4"
        cols="12"
      >
        <v-text-field
          v-model="middlename"
          :loading="empGetting"
        >
          <template v-slot:label>
            <span>Middle Name</span><small class="grey--text ml-2">(optional)</small>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        lg="4"
        md="4"
        cols="12"
      >
        <v-text-field
          label="Last Name"
          v-model="lastname"
          :loading="empGetting"
        ></v-text-field>
      </v-col>
      <v-col
        lg="6"
        md="6"
        cols="12"
      >
        <v-text-field
          v-model="email"
          type="email"
          :rules="[verifyEmail]"
          :loading="empGetting"
        >
          <template v-slot:label>
            <span>Email</span><small class="grey--text ml-2">(optional)</small>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        lg="6"
        md="6"
        cols="12"
      >
        <v-text-field
          v-model="address"
          :loading="empGetting"
        >
          <template v-slot:label>
            <span>Address</span><small class="grey--text ml-2">(optional)</small>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        lg="4"
        md="4"
        cols="12"
      >
        <v-text-field
          v-model="businessNo"
          :loading="empGetting"
        >
          <template v-slot:label>
            <span>Business No</span><small class="grey--text ml-2">(optional)</small>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        lg="4"
        md="4"
        cols="12"
      >
        <v-text-field
          v-model="mobileNo"
          :loading="empGetting"
        >
          <template v-slot:label>
            <span>Mobile No</span><small class="grey--text ml-2">(optional)</small>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        lg="4"
        md="4"
        cols="12"
      >
        <v-text-field
          v-model="homeNo"
          :loading="empGetting"
        >
          <template v-slot:label>
            <span>Home No</span><small class="grey--text ml-2">(optional)</small>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="notes"
          rows="1"
          no-resize
          auto-grow
          :loading="empGetting"
        >
          <template v-slot:label>
            <span>Notes</span><small class="grey--text ml-2">(optional)</small>
          </template>
        </v-textarea>
      </v-col>
      <v-col
        lg="1"
        md="1"
        cols="12"
      >
        <v-btn
          color="primary"
          dark
          block
          :loading="empPutting"
          @click="save"
        >Save</v-btn>
      </v-col>
      <v-col
        lg="1"
        md="1"
        cols="12"
      >
        <v-btn
          color="red"
          dark
          block
          @click="$router.push('/employees')"
        >Cancel</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import { getVars, putVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'
import empRepository from '@/repositories/employee.repository'

const saveDelay = searchDelay()
const getDelay = searchDelay()

const snakeToCamel = str => str.replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''))

const defaultInputs = () => ({
  employeeId: null,
  firstname: null,
  middlename: null,
  lastname: null,
  businessNo: null,
  mobileNo: null,
  homeNo: null,
  address: null,
  email: null,
  notes: null,
})

export default {
  name: 'EditEmployee',
  data () {
    return {
      ...defaultInputs(),
      ...putVars('emp'),
      ...getVars('emp'),
    }
  },
  created () {
    this.employeeId = this.$route.params.employee
    this.getEmployee()
  },
  computed: {
    employeeName () {
      let ret = ''
      if (this.firstname) {
        if (!this.middlename) {
          ret = `${this.firstname} ${this.lastname}`
        } else {
          ret = `${this.firstname} ${this.middlename.toUpperCase().substr(0, 1)}. ${this.lastname}`
        }
      }
      return ret
    },
  },
  methods: {
    verifyEmail (email = '') {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return (re.test(email ? email.toLowerCase() : '') || !email) || 'Invalid Email Address'
    },
    save () {
      if (!this.empPutting) {
        this.empPutting = true
        saveDelay(() => {
          const data = {
            id: this.employeeId,
            firstname: this.firstname,
            middlename: this.middlename,
            lastname: this.lastname,
            business_no: this.businessNo,
            mobile_no: this.mobileNo,
            home_no: this.homeNo,
            address: this.address,
            email: this.email,
            notes: this.notes,
          }
          this.empPutErrors = []
          empRepository.update(this.employeeId, data)
            .then(() => { this.$router.push('/employees') })
            .catch(e => { this.empPutErrors = errorHandler(e) })
            .then(() => { this.empPutting = false })
        })
      }
    },
    getEmployee () {
      if (!this.empGetting) {
        this.empGetting = true
        getDelay(() => {
          this.empGetErrors = []
          empRepository.get(this.employeeId)
            .then(({ data }) => {
              if (!data) this.$router.push('/employees')
              this.populateInputs(data)
            })
            .catch(e => { this.empGetErrors = errorHandler(e) })
            .then(() => { this.empGetting = false })
        })
      }
    },
    populateInputs (data) {
      Object.keys(data).forEach(e => {
        if (e !== 'id') this[snakeToCamel(e)] = data[e]
      })
    },
    reset (vars) {
      Object.keys(defaultInputs()).forEach(e => {
        this[e] = vars[e]
      })
    },
  },
}
</script>
